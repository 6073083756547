import React, { useEffect, useState } from "react"

import cn from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl } from "react-intl"
import { TransitionGroup } from "react-transition-group"

import FilterByTag from "~/components/FilterByTag/FilterByTag"
import Link from "~/components/Link/Link"
import { Button, Col, Heading, Row } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { CoCreationData, CoCreationProject } from "~/models/CoCreationProjects"

import css from "./CoCreationProjects.module.scss"

interface PropTypes {
  isDarkTheme?: boolean
  eagerImages?: boolean
  coCreationProjects: CoCreationProject[]
}

const LOADING_THRESHOLD = { mobile: 7, desktop: 8 }

// Eager-loads first 3 project images if on mobile view
const MOBILE_EAGERLOAD_THRESHOLD = 3

const CoCreationProjects = ({
  isDarkTheme,
  eagerImages,
  coCreationProjects,
}: PropTypes) => {
  const intl = useIntl()
  const isCompact = useIsOnCompact()
  const projects = coCreationProjects.map(project => {
    return { ...project.node.data, uid: project.node.uid }
  })

  const loadingThreshold = isCompact
    ? LOADING_THRESHOLD.mobile
    : LOADING_THRESHOLD.desktop

  const [showButton, setShowButton] = useState<boolean>(true)
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [shownProjectsAmount, setShownProjectsAmount] =
    useState(loadingThreshold)

  // If filter is off, account for all projects
  const filteredProjecsCount = filteredProjects.length

  // Show Load-more button only if there are more projects to show
  const moreProjectsToShow = filteredProjecsCount > shownProjectsAmount

  const showMore = () => {
    if (!moreProjectsToShow) return
    setShownProjectsAmount(
      prevAmount =>
        prevAmount + (isCompact ? loadingThreshold - 1 : loadingThreshold)
    )
  }

  // To update show-more button when filter is applied
  useEffect(() => {
    setShowButton(moreProjectsToShow)
  }, [moreProjectsToShow])

  useEffect(() => {
    setShownProjectsAmount(loadingThreshold)
  }, [isCompact])

  const allTags = [
    {
      translation: intl.formatMessage({
        id: "co-creation/text:state-in-progress",
      }),
      slug: "started",
    },
    {
      translation: intl.formatMessage({
        id: "co-creation/text:state-finished",
      }),
      slug: "finished",
    },
    {
      translation: intl.formatMessage({
        id: "co-creation/text:state-upcoming",
      }),
      slug: "upcoming",
    },
  ]

  const filterByStatus = (projects: CoCreationData[], tag: string) => {
    setShownProjectsAmount(loadingThreshold) // Reset shown amount when filter is applied

    return projects.filter(project => {
      // Project is considered started if started is true and finished is false
      if (tag === "started") return project.started && !project.finished
      // Project is considered finished if finished is true
      if (tag === "finished") return project.finished
      // Project is considered upcoming if started is false and finished is false
      if (tag === "upcoming") return !project.started && !project.finished
    })
  }

  const projectsAmount = intl.formatMessage(
    {
      id: "tags/text:projects-amount",
    },
    {
      count: filteredProjecsCount,
    }
  )

  const isEagerLoadable = (id: number) => {
    return eagerImages && id < MOBILE_EAGERLOAD_THRESHOLD ? "eager" : "lazy"
  }

  const isFullWidth = (id: number) => {
    return isCompact && id === 0
  }

  return (
    <div
      className={cn(css.root, {
        [css.darkTheme]: isDarkTheme,
      })}
    >
      <Heading className={css.cocreationTitle} variant="large">
        {intl.formatMessage({
          id: "cocreation/text:title",
        })}
      </Heading>
      <FilterByTag
        tags={allTags}
        items={projects ?? []}
        itemsCount={projectsAmount}
        setFilteredItems={setFilteredProjects}
        filteringLogic={filterByStatus}
        isDarkTheme={isDarkTheme}
      />
      <TransitionGroup>
        <Row className={css.projectsContainer}>
          {filteredProjects
            ?.slice(0, shownProjectsAmount)
            .map((project: CoCreationData, id: number) => (
              <Col
                key={id}
                className={cn(css.project, {
                  [css.fullWidth]: isFullWidth(id),
                })}
                width={[isFullWidth(id) ? 12 : 6, 3, 3]}
              >
                <Link
                  className={css.projectLink}
                  to="community/cocreation/cocreation-details"
                  params={{ uid: project.uid }}
                >
                  <GatsbyImage
                    className={css.projectImage}
                    image={project.thumbnail.gatsbyImageData}
                    alt={project.subtitle.text}
                    loading={isCompact ? isEagerLoadable(id) : undefined}
                  />
                </Link>
                <Heading
                  className={css.projectTitle}
                  variant="extraSmall"
                  Tag="h3"
                  color={isDarkTheme ? "white" : "body"}
                >
                  {project.title.text}
                </Heading>
              </Col>
            ))}
        </Row>
      </TransitionGroup>
      {showButton && (
        <div className={css.buttonContainer}>
          <Button
            fullWidth={true}
            variant="tertiary"
            size="medium"
            color="original"
            onClick={showMore}
          >
            {intl.formatMessage({
              id: "co-creation/action:show-more-projects",
            })}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CoCreationProjects
