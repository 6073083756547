import React from "react"

import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

import Tag from "../../LatestLandings/Cards/Tag/Tag"
import { Body, Heading } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"

import css from "./LatestLandingsByTagsSmallCard.module.scss"

export interface LatestLandingProps {
  landing: LatestLanding
  tag?: string
  isDarkTheme?: boolean
  tagColor?: string
  eagerImages?: boolean
}

function LatestLandingsByTagsSmallCard({
  landing,
  tag,
  isDarkTheme,
  tagColor,
  eagerImages,
}: LatestLandingProps) {
  return (
    <>
      {landing.data.seoImage.gatsbyImageData && (
        <div className={css.content}>
          <GatsbyImage
            loading={eagerImages ? "eager" : undefined}
            image={landing.data.seoImage.gatsbyImageData}
            alt={landing.data.seoImage.alt || ""}
            className={css.image}
          />
          <div className={css.header}>
            <Tag tag={tag ? tag : "All"} tagColor={tagColor} />
            <Heading
              variant="extraSmall"
              className={css.title}
              color={isDarkTheme ? "white" : "body"}
            >
              {landing.data.metaTitle}
            </Heading>
          </div>
          <Body
            className={css.hideInMobile}
            variant="body3"
            color={isDarkTheme ? "white" : "body"}
          >
            {landing.data.meta_description}
          </Body>
        </div>
      )}
    </>
  )
}

export default LatestLandingsByTagsSmallCard
