import React, { CSSProperties, useContext } from "react"

import cn from "classnames"
import FluidObject from "gatsby-image"
import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
} from "gatsby-plugin-image"

import Carousel from "~/components/Carousel/Carousel"
import FullWidthCarousel from "~/components/FullWidthCarousel/FullWidthCarousel"
import { Container, Heading } from "~/components/ui"
import { ThemeContext } from "~/context/ThemeContext"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { mobileScreenWidth } from "~/utils/general"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./FreeCarousel.module.scss"

type ItemStyle = "square" | "circle" | "full_width_item"

export type ItemSize = "big" | "normal" | "small"

type TextAlign = "default" | "left" | "center" | "right" | "justify"

interface OverlayLink {
  link: PrismicLink
  label: string
}

export interface ItemProps {
  title: PrismicStructuredText
  link: PrismicLink
  image?: {
    gatsbyImageData?: IGatsbyImageData
    fluid: FluidObject | null
    dimensions: {
      width: number
    }
    thumbnails: {
      full_width_mobile: null | {
        gatsbyImageData: IGatsbyImageData
        fluid: FluidObject | null
      }
    }
  }
  wideImage?: {
    gatsbyImageData: IGatsbyImageData
    fluid: FluidObject | null
  }
  color: null | CSSProperties["color"]
  overlayLink?: OverlayLink
}

interface SlideProps extends ItemProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  itemStyle: ItemStyle
  itemSize?: ItemSize
  textAlign?: TextAlign
  eagerImages?: boolean
}

function Slide({
  title,
  image,
  color,
  link,
  overlayLink,
  itemStyle,
  textAlign,
  isDarkTheme,
  itemSize,
  eagerImages,
}: SlideProps) {
  const theme = useContext(ThemeContext)

  const showImage = !color
  const isCircle = itemStyle === "circle"

  const isCreatorsLanding = theme === "creators"

  const slidePlaceholderStyle = {
    backgroundColor: showImage || !color ? "" : color,
  }

  const linkAttr = getPrismicLinkProps(overlayLink?.link || link)
  const label = overlayLink ? overlayLink.label : title.text

  const displayLinkAsOverlay = !showImage || !!overlayLink
  const overlayTextVariant =
    theme === "kids"
      ? "extraSmallCampingHoliday"
      : theme === "food"
      ? "extraSmallHandDrawn"
      : "medium"
  const overlayTextColor = "white"

  return (
    <a className={css.slide} {...linkAttr}>
      <div className={cn(css.slideInner, isCircle && css.circle)}>
        {showImage && image?.gatsbyImageData ? (
          <div className={css.slideImage}>
            <GatsbyImage
              image={
                itemSize === "big" &&
                itemStyle === "square" &&
                image.dimensions.width < 200
                  ? image.thumbnails.full_width_mobile?.gatsbyImageData ??
                    image.gatsbyImageData
                  : image.gatsbyImageData
              }
              alt=""
              loading={eagerImages ? "eager" : undefined}
              className={css.gatsbyImage}
            />
          </div>
        ) : (
          <div
            className={cn(css.slidePlaceholder, isCircle && css.circle)}
            style={slidePlaceholderStyle}
          />
        )}

        {displayLinkAsOverlay && (
          <div
            className={cn(css.overlayLink, {
              [css.isFoodTheme]: theme === "food",
              [css.dimBackground]: showImage,
              [css.isCircle]: isCircle,
            })}
          >
            <Heading
              Tag="span"
              variant={overlayTextVariant}
              color={overlayTextColor}
              align="center"
            >
              {label}
            </Heading>
          </div>
        )}
      </div>

      {!displayLinkAsOverlay && !!title.text && (
        <Heading
          className={cn(css.slideTitle, {
            [css.singleLineEllipsis]: isCreatorsLanding,
            [css.multiLineEllipsis]: !isCreatorsLanding,
          })}
          Tag="h3"
          variant="extraSmall"
          align={textAlign !== "default" ? textAlign : undefined}
          color={isDarkTheme ? "white" : "body"}
        >
          {label}
        </Heading>
      )}
    </a>
  )
}

export interface FreeCarouselProps {
  items: ItemProps[]
  primary: {
    title: PrismicStructuredText
    carouselLink: PrismicLink
    carouselLinkLabel: string | null
    itemStyle: ItemStyle
    itemSize: ItemSize
    textAlign: TextAlign
    anchorId?: string
  }
}

interface PropTypes extends FreeCarouselProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function FreeCarousel({
  items,
  primary,
  isPreview,
  isDarkTheme,
  eagerImages,
}: PropTypes) {
  const {
    title,
    carouselLink,
    carouselLinkLabel,
    itemStyle,
    itemSize,
    textAlign,
  } = primary

  let link: OverlayLink | undefined = undefined
  if (carouselLink.linkType !== "Any" && carouselLinkLabel !== null) {
    link = { link: carouselLink, label: carouselLinkLabel }
  }

  if (itemStyle === "full_width_item") {
    return (
      <FullWidthCarousel
        items={items.map(item => ({
          title: item.title.text,
          link: item.link ? getPrismicLinkProps(item.link)?.href : undefined,
          image: {
            gatsbyImageData:
              item.wideImage?.gatsbyImageData &&
              withArtDirection(item.wideImage?.gatsbyImageData, [
                {
                  media: `(max-width: ${mobileScreenWidth})`,
                  image: item.image?.thumbnails.full_width_mobile
                    ? item.image.thumbnails.full_width_mobile.gatsbyImageData
                    : item.wideImage?.gatsbyImageData,
                },
              ]),
            fluid: null,
          },
          color: item.color,
        }))}
        eagerImages={eagerImages}
      />
    )
  }

  return (
    <>
      {title.text && (
        <Container>
          <Heading variant="large" color={isDarkTheme ? "white" : "body"}>
            {title.text}
          </Heading>
        </Container>
      )}
      <Carousel itemSize={itemSize}>
        {items.map((item, i) => (
          <Slide
            textAlign={textAlign}
            key={i}
            overlayLink={i === items.length - 1 ? link : undefined}
            itemStyle={itemStyle}
            itemSize={itemSize}
            {...{ isPreview, isDarkTheme, eagerImages, ...item }}
          />
        ))}
      </Carousel>
    </>
  )
}

export default FreeCarousel
