import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import Tag from "./Tag/Tag"
import { Heading } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"

import css from "./LandingsSmallCard.module.scss"

export interface LatestLandingProps {
  landing: LatestLanding
  tag: string
  isDarkTheme?: boolean
  tagColor?: string
  eagerImages?: boolean
}

function LandingsSmallCard({
  landing,
  tag,
  isDarkTheme,
  tagColor,
  eagerImages,
}: LatestLandingProps) {
  return (
    <div className={css.content}>
      {landing.data.seoImage.gatsbyImageData && (
        <GatsbyImage
          loading={eagerImages ? "eager" : undefined}
          image={landing.data.seoImage.gatsbyImageData}
          alt={landing.data.seoImage.alt || ""}
          className={css.image}
        />
      )}
      <div className={css.header}>
        {tag && <Tag tag={tag} tagColor={tagColor} />}
        <Heading
          variant="extraSmall"
          className={css.title}
          color={isDarkTheme ? "white" : "body"}
        >
          {landing.data.metaTitle}
        </Heading>
      </div>
    </div>
  )
}

export default LandingsSmallCard
