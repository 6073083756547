import React, { useEffect } from "react"

import loadable from "@loadable/component"
import cn from "classnames"
import Img, { FluidObject } from "gatsby-image"

import { getVideoControlsProps } from "../common/SliceUtils"
import { launchSnow } from "~/components/slices/FullWidthBanner/snow"
import { Heading } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import Vertical from "~/models/Vertical"
import { Video as IVideo } from "~/models/Video"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./FullWidthBanner.module.scss"

const VideoPlayer = loadable(
  () => import("~/components/VideoPlayer/VideoPlayer")
)
const RenderLottie = loadable(() => import("~/components/Lottie/Lottie"))

export type BannerHeight =
  | "Main"
  | "Mob_375x280px"
  | "Mob_375x400px"
  | "Mob_375x614px"

type ImageData = {
  alt: string | null
  fluid: FluidObject | null
  thumbnails: {
    Mob_375x280px: null | {
      fluid: FluidObject | null
    }
    Mob_375x400px: null | {
      fluid: FluidObject | null
    }
    Mob_375x614px: null | {
      fluid: FluidObject | null
    }
  }
}

type MobileImageData = {
  alt: string | null
  fluid: FluidObject | null
  thumbnails: {
    Mob_375x280px: null | {
      fluid: FluidObject | null
    }
    Mob_375x400px: null | {
      fluid: FluidObject | null
    }
    Mob_375x614px: null | {
      fluid: FluidObject | null
    }
  }
}

export interface FullWidthBannerProps {
  anchorId?: string
  bannerHeight: BannerHeight
  image: ImageData
  mobileImage?: MobileImageData
  title1: PrismicStructuredText
  scrollArrowButton?: boolean
  specialFeature?: "Snow"
  lottieDesktop?: {
    url: null | string
  }
  lottieMobile?: {
    url: null | string
  }
  desktopVideoUid?: string
  mobileVideoUid?: string
}

interface PropTypes extends FullWidthBannerProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  videos?: IVideo[]
  eagerImages?: boolean
}

function getImageFullWidth(
  isCompact: boolean,
  bannerHeight: BannerHeight,
  image: ImageData,
  mobileImage?: MobileImageData,
  eagerImages?: boolean
) {
  const imageClassNames = cn(css.image, {
    [css[`variant${bannerHeight}`]]: true,
  })
  if (isCompact) {
    const mobileImageFluid =
      bannerHeight === "Mob_375x280px"
        ? mobileImage?.thumbnails.Mob_375x280px?.fluid
        : bannerHeight === "Mob_375x400px"
        ? mobileImage?.thumbnails.Mob_375x400px?.fluid
        : bannerHeight === "Mob_375x614px"
        ? mobileImage?.thumbnails.Mob_375x614px?.fluid
        : mobileImage?.fluid

    if (mobileImageFluid?.src) {
      return (
        <Img
          className={imageClassNames}
          fluid={mobileImageFluid}
          alt={image.alt || ""}
          loading={eagerImages ? "eager" : undefined}
        />
      )
    }
  } else {
    const desktopImageFluid =
      bannerHeight === "Mob_375x280px"
        ? image.thumbnails.Mob_375x280px?.fluid
        : bannerHeight === "Mob_375x400px"
        ? image.thumbnails.Mob_375x400px?.fluid
        : bannerHeight === "Mob_375x614px"
        ? image.thumbnails.Mob_375x614px?.fluid
        : image.fluid

    if (desktopImageFluid?.src) {
      return (
        <Img
          className={imageClassNames}
          fluid={desktopImageFluid}
          alt={image.alt || ""}
          loading={eagerImages ? "eager" : undefined}
        />
      )
    }
  }
  return null
}

function getVideoFullWidth(
  videos: IVideo[] | undefined,
  isCompact: boolean,
  mobileVideoUid: string | undefined,
  desktopVideoUid: string | undefined
) {
  let video: IVideo | null | undefined = null

  if (!videos) video = null

  const mobileVideo = videos?.find(video => video.id === mobileVideoUid)
  const desktopVideo = videos?.find(video => video.id === desktopVideoUid)

  if (isCompact) {
    video = mobileVideo ?? null
  } else {
    video = desktopVideo ?? null
  }
  const videoControls = getVideoControlsProps("Hide controls and auto-play")

  return (
    video && (
      <VideoPlayer
        poster={video.videoPreview}
        src={video.hostedVideoUrl}
        verticalColor={Vertical.Original.color}
        format={{ mobile: "square", desktop: "landscape" }}
        loop
        {...videoControls}
      />
    )
  )
}

function FullWidthBanner({
  bannerHeight,
  image,
  mobileImage,
  title1,
  scrollArrowButton,
  specialFeature,
  lottieDesktop,
  lottieMobile,
  desktopVideoUid,
  mobileVideoUid,
  videos,
  eagerImages,
}: PropTypes) {
  const isCompact = useIsOnCompact()

  const snowClassNames = cn(
    css.snow,
    {
      [css[`variant${bannerHeight}`]]: true,
    },
    {
      [css[`hasCTA`]]: scrollArrowButton,
    }
  )

  useEffect(() => {
    specialFeature && specialFeature === "Snow" && launchSnow()
  }, [])

  const pixelsScrollDown = isCompact
    ? bannerHeight === "Mob_375x614px"
      ? 614
      : 375
    : bannerHeight === "Mob_375x614px"
    ? 540
    : bannerHeight === "Mob_375x400px"
    ? 400
    : 420 // "Mob_375x280px"

  const Image = getImageFullWidth(
    isCompact,
    bannerHeight,
    image,
    mobileImage,
    eagerImages
  )

  const Video = getVideoFullWidth(
    videos,
    isCompact,
    mobileVideoUid,
    desktopVideoUid
  )

  return (
    <div
      className={css.cover}
      style={
        Video
          ? {
              maxHeight: "var(--max-height-video)",
              overflow: "hidden",
            }
          : undefined
      }
    >
      <div className={css.content}>
        {specialFeature && specialFeature == "Snow" && (
          <canvas id="snow" className={snowClassNames} />
        )}
        {Video && <div className={css.videoDesktop}>{Video}</div>}
        {isCompact && lottieMobile?.url && (
          <div className={css.lottieMobile}>
            <RenderLottie
              url={lottieMobile.url}
              preserveAspectRatioOpt="xMidYMid slice"
              style={{
                height: "100%",
              }}
            />
          </div>
        )}
        {!isCompact && lottieDesktop?.url && (
          <div className={css.lottieDesktop}>
            <RenderLottie
              url={lottieDesktop.url}
              preserveAspectRatioOpt="xMidYMid slice"
              style={{
                height: "100%",
              }}
            />
          </div>
        )}
        {Image ?? null}
      </div>
      {scrollArrowButton && (
        <div
          className={css.scrollButton}
          onClick={() =>
            scroll({
              top: pixelsScrollDown,
              behavior: "smooth",
            })
          }
        >
          <span className={css.downArrow}></span>
        </div>
      )}
      {title1.text && (
        <Heading
          className={css.title}
          align="center"
          color="white"
          variant="large"
        >
          {title1.text}
        </Heading>
      )}
    </div>
  )
}

export default FullWidthBanner
