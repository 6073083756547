import React, { CSSProperties } from "react"

import cn from "classnames"

import Link from "~/components/Link/Link"
import { Body, Heading } from "~/components/ui"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./Tiles.module.scss"

export interface TilesProps {
  displayMax?: number
  seeMoreLink?: PrismicLink
  imageForm?: string
  title: PrismicStructuredText
  colorSeeMoreLink?: CSSProperties["color"]
  content: {
    illustration?: string
    text: string
    tag?: string
    link?: string
  }[]
}

interface PropTypes extends TilesProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  headerIsNotHx?: boolean
}

function Tiles({
  title,
  displayMax,
  seeMoreLink,
  imageForm = "square",
  content,
  colorSeeMoreLink,
  headerIsNotHx,
}: PropTypes) {
  return (
    <div className={css.container}>
      <Heading Tag={headerIsNotHx ? "p" : undefined} variant="small">
        {title.text}
      </Heading>
      <div className={css.items}>
        {content.map((item, index) => {
          return (
            <Link
              to={item.link ?? ""}
              key={index}
              className={css.item}
              absoluteLink
            >
              {item.illustration && (
                <img
                  className={css.image}
                  src={item.illustration}
                  alt={item.text}
                />
              )}
              <div className={css.textContent}>
                <div className={css.text}>
                  <Body variant="body1" bold color={"white"} uppercase>
                    {item.text}
                  </Body>
                </div>
              </div>
            </Link>
          )
        })}
        {seeMoreLink && (
          <Link
            to={seeMoreLink.url}
            className={cn(css.item, css.seeMoreLink)}
            absoluteLink
            style={colorSeeMoreLink ? { borderColor: colorSeeMoreLink } : {}}
          >
            <div className={css.textContent}>
              <div className={cn(css.text, css.seeMoreText)}>
                <Body
                  variant="control"
                  bold
                  color={colorSeeMoreLink ? colorSeeMoreLink : "original"}
                  uppercase
                >
                  Voir plus
                </Body>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Tiles
