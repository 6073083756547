import React, { CSSProperties } from "react"

import { FluidObject } from "gatsby-image"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { Heading } from "~/components/ui"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./FlipTiles.module.scss"

export interface FlipTilesProps {
  primary: {
    title: PrismicStructuredText
    anchorId?: string
  }
  items: {
    image: { fluid: FluidObject | null; gatsbyImageData?: IGatsbyImageData }
    backgroundColor: CSSProperties["color"]
    text: PrismicStructuredText
    link: PrismicLink
  }[]
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function FlipTiles({
  primary,
  items,
  isDarkTheme,
  eagerImages,
}: FlipTilesProps) {
  return (
    <>
      <Heading Tag="h2" align="center" color={isDarkTheme ? "white" : "body"}>
        {primary.title.text}
      </Heading>
      <div className={css.content}>
        {items.map((item, index) => {
          const versoContent = (
            <div style={{ width: "100%" }}>
              <Heading variant="small" Tag="h2" align="center" color="white">
                {item.text.text}
              </Heading>
            </div>
          )
          return (
            <div
              className={css.tileWrapper}
              style={
                {
                  "--current-theme-color": item.backgroundColor,
                } as CSSProperties
              }
              key={index}
            >
              <div className={css.tile}>
                <div className={css.recto}>
                  {!!item.image.gatsbyImageData && (
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt=""
                      loading={eagerImages ? "eager" : undefined}
                    />
                  )}
                </div>
                {item.link.url ? (
                  <a className={css.verso} href={item.link.url}>
                    {versoContent}
                  </a>
                ) : (
                  <div className={css.verso}>{versoContent}</div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default FlipTiles
