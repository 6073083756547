import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List, { ListProps } from "~/components/slices/List/List"
import { PrismicStructuredText } from "~/models/PrismicTypes"

interface PropTypes extends PrismicListFromDiets {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PrismicNavMenuListFromDiets(props: PropTypes) {
  const content = [
    {
      illustration:
        "https://images.prismic.io/chefclub/e2e8e2ca-37a5-4b2a-954a-778c97452208_Elections.png?ixlib=gatsbyFP&fit=max&auto=compress%2Cformat&q=90&rect=0%2C0%2C935%2C525&w=456&h=256&dpr=1 ",
      text: "Diet 1",
      tag: "Diet",
      link: "/diet-1",
    },
  ]
  return (
    <SliceSection {...props}>
      <List headerIsNotHx={true} content={content} {...props.primary} />
    </SliceSection>
  )
}

export default PrismicNavMenuListFromDiets

interface primary {
  title: PrismicStructuredText
  displayMax: number
  displaySeeMoreItem: boolean
  imageForm: string
}

export interface PrismicListFromDiets extends PrismicNavSlice {
  sliceType: "list"
  variation: "fromDiets"
  primary: primary
}

export const query = graphql`
  fragment PrismicListFromDiets on PrismicListFromDiets {
    id
    sliceType: slice_type
    variation
    primary {
      displayMax
      displaySeeMoreItem
      imageForm
      title {
        ...PrismicStructuredText
      }
    }
  }
`
