import React, { CSSProperties, useEffect, useRef, useState } from "react"

import cn from "classnames"
import { FluidObject } from "gatsby-image"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { useIntl } from "react-intl"

import { Button, Heading } from "~/components/ui"
import useEventListener from "~/hooks/useEventListener"

import css from "./FullWidthCarousel.module.scss"

type CarouselItem = {
  title: string
  link?: string
  image: {
    fluid: FluidObject | null
    gatsbyImageData?: IGatsbyImageData
  }
  color: null | CSSProperties["color"]
}

type PropType = {
  items: CarouselItem[]
  eagerImages?: boolean
}

const AUTOPLAY_DELAY = 5000

const FullWidthCarousel = ({ items, eagerImages }: PropType) => {
  const scrollAreaRef = useRef<HTMLDivElement>(null)

  const [displayedIndex, setDisplayedIndex] = useState<number>(0)
  const [autoScrollInterval, setAutoScrollInterval] = useState<any>()

  useEffect(() => {
    if (!autoScrollInterval) {
      setAutoScrollInterval(setInterval(() => next(), AUTOPLAY_DELAY))
    }
  }, [autoScrollInterval])

  const next = () => {
    const left = scrollAreaRef.current?.scrollLeft || 0
    let newPage =
      1 +
      Math.floor(
        scrollAreaRef.current?.offsetWidth
          ? (left + scrollAreaRef.current?.offsetWidth / 2) /
              scrollAreaRef.current?.offsetWidth
          : 1
      )
    newPage = newPage < items.length ? newPage : 0
    goTo(
      newPage === 0 && displayedIndex + 1 === items.length
        ? items.length
        : newPage
    )
  }

  const goTo = (index: number) => {
    scrollTo(
      scrollAreaRef.current?.offsetWidth
        ? scrollAreaRef.current?.offsetWidth * index
        : 0,
      index !== 0
    )
  }

  const scrollTo = (left: number, smooth = true) => {
    if (scrollAreaRef?.current) {
      scrollAreaRef.current.scrollTo({
        left,
        behavior: smooth ? "smooth" : "auto",
      })
    }
  }

  useEventListener(
    "scroll",
    () => {
      const left = scrollAreaRef.current?.scrollLeft || 0

      const newPage = Math.floor(
        scrollAreaRef.current?.offsetWidth
          ? (left + scrollAreaRef.current?.offsetWidth / 2) /
              scrollAreaRef.current?.offsetWidth
          : 1
      )

      setDisplayedIndex(newPage < items.length ? newPage : 0)
      if (autoScrollInterval) {
        clearInterval(autoScrollInterval)
        setAutoScrollInterval(null)
      }
    },
    scrollAreaRef
  )

  return (
    <div className={css.root} ref={scrollAreaRef}>
      <div className={css.itemsContainer}>
        {[...items, items[0]].map((item: CarouselItem, index) => (
          <Item key={index} item={item} eagerImages={eagerImages} />
        ))}
      </div>
      <div className={css.pageDotsContainer}>
        {items.map((item, index) => (
          <div
            className={cn(
              css.dot,
              displayedIndex === index ? css.displayed : undefined
            )}
            key={index}
            onClick={() => goTo(index)}
          />
        ))}
      </div>
    </div>
  )
}

type ItemPropType = {
  item: CarouselItem
  eagerImages?: boolean
}

const Item = ({ item, eagerImages }: ItemPropType) => {
  const intl = useIntl()
  const showImage = !item.color
  const slidePlaceholderStyle = {
    backgroundColor:
      showImage || !item.color ? "var(--gray3-color)" : item.color,
  }

  return (
    <a href={item.link} className={css.itemRoot}>
      {showImage && item.image?.gatsbyImageData ? (
        <div className={css.slideImage}>
          <GatsbyImage
            image={item.image.gatsbyImageData}
            alt={item.title}
            loading={eagerImages ? "eager" : undefined}
          />
        </div>
      ) : (
        <div className={css.slidePlaceholder} style={slidePlaceholderStyle} />
      )}
      <div className={css.opacifier} />
      <div className={css.container}>
        <Heading
          variant="extraExtraLarge"
          Tag="p"
          className={css.itemContent}
          color="white"
        >
          {item.title}
        </Heading>
        <Button
          variant="secondary"
          color={"white"}
          className={css.itemContent}
          autoWidth
          uppercase
        >
          {intl.formatMessage({
            id: "prismic-slice/free-carousel/action:discover",
          })}
        </Button>
      </div>
    </a>
  )
}

export default FullWidthCarousel
