import { HTMLProps } from "react"

import { Link } from "prismic-reactjs"

import { assertNever } from "./general"
import { getIntl, getLocaleFromPrismicLocale } from "./locales"
import { PrismicDocumentLink, PrismicLink } from "~/models/PrismicTypes"
import pathFromFullId from "~/routes/pathFromFullId"
import { ChefclubLocale } from "~/utils/locales"

export function pathForPrismicDoc({
  typeId,
  documentUID,
  locale,
  preview = false,
}: {
  typeId: string
  documentUID: string | undefined
  locale: ChefclubLocale
  preview?: boolean
}) {
  const intl = getIntl(locale)

  function path(fullId: string, params: any = {}): string {
    return pathFromFullId(fullId, intl, params)
  }

  switch (typeId) {
    case "homepage":
      return path("locale")
    case "landing":
      return preview
        ? path("preview/locale/landing")
        : path("locale/s/landing", { uid: documentUID })
    case "workshop_page":
      return path("locale/workshop")
    case "our_story_page_v2":
      return path("locale/story")
    case "kids_converter_page":
      return path(`locale/kids-converter`, { uid: documentUID })
    default:
      console.error(
        `PrismicLink.ts: Couldn't resolve link to unexpected document type “${typeId}”`
      )
      return null
  }
}

// See: https://prismic.io/docs/reactjs/beyond-the-api/link-resolving
export const linkResolver = (doc: PrismicDocumentLink) => {
  const locale = getLocaleFromPrismicLocale(doc.lang)

  const path = pathForPrismicDoc({
    typeId: doc.type,
    documentUID: doc.uid,
    locale,
  })

  return path || ""
}

type PrismicLinkReturn = Pick<
  HTMLProps<HTMLAnchorElement>,
  "href" | "rel" | "target"
> | null

// TODO: It would be cool to be able to return  either <a href...> or <Link to...>
export const getPrismicLinkProps = (link: PrismicLink): PrismicLinkReturn => {
  switch (link.linkType) {
    case "Document":
      return { href: Link.url(link, linkResolver) }

    case "Web":
      const webTarget = {
        target: link?.target || "_self",
      }

      return { href: link.url, rel: "noreferrer", ...webTarget }

    case "Media":
      const mediaTarget = link?.target
        ? { target: link.target, rel: "noopener" }
        : {}

      return { href: link.url, ...mediaTarget }

    case "Any":
      return null

    default:
      return assertNever(link)
  }
}
